import React from "react";
import styled from 'styled-components';
import config from "../../config"

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    }; 
  }

  render(props) {
    const { status } = this.state;
    return (
      <HtmlForm
        onSubmit={this.submitForm}
        action={"https://formspree.io/" + this.props.formid}
        method="POST"
      >
        <input type="text" name="name" placeholder="Your name" required/>
        <input type="email" name="email" placeholder="Your email address" required />
        <input type="phone" name="phone" placeholder="Your phone number" required />
        <textarea name="message" placeholder="Job description or query" required />
        <input type="text" name="_gotcha" style={{display: `none`}} />

        
        {status === "SUCCESS" ? <p><br/><strong>Thanks for your message, we will get back to you soon!</strong></p> : <button><i className="fas fa-paper-plane"></i> Send</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </HtmlForm>
 
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
 
const HtmlForm = styled.form`
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  
  input[type="text"],
  input[type="email"],
  input[type="phone"]  {
    background: none;
    border-width: 0 0 2px 0;
    border-color: ${config.primaryColor};
    color: #000;
    outline: none;
    margin-bottom: 30px;
  }

  input.gotcha {
    visibility: hidden;
    margin: 0;
    height: 0;
  }

  textarea {
    background: none;
    border-color: ${config.primaryColor};
    color: #000;
    outline: none;
    border-width: 0 0 2px 0;
    margin-bottom: 20px;
    padding: 5px;
  }

  button {
    display: inline-flex;
    justify-content: center;
    max-width: 120px;
    color: #fff;
    padding: 10px 20px;
    background: ${config.primaryColor};
    border: 2px solid${config.primaryColor};

    &:hover {
      cursor: pointer;
      color: ${config.secondaryColor};
      border: 2px solid ${config.primaryColor};
    }

    i {
      margin-right: 5px;
    }
  }
  
`