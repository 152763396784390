import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import BgImage from "../../components/BgImage";
import Button from "../../components/button/button";

const ViewWork = () => {
  const data = useStaticQuery(graphql`
    query {
      viewWork: file(relativePath: { eq: "section/view-work-section.jpg" }) {
	      childImageSharp {
	        fluid(maxWidth: 1200) {
	          ...GatsbyImageSharpFluid
	        }
	      }
	    }
    }
  `)

  return (

   <BgImage title="LP Brickwork & construction" fluid={data.viewWork.childImageSharp.fluid} overlayColor="#00000069" mobileHeight="400px">
      <WorkContainer>
        <WorkOverlay>
          <h2>View our work</h2>
          <p>Take a look through our gallery of work that we've completed recently for our happy customers.</p>
          <Button text="View work" link="/gallery" />
        </WorkOverlay>
      </WorkContainer>
    </BgImage>

  );
}

const WorkContainer = styled.div `
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const WorkOverlay = styled.div`
  width: 33%;
  background: #fff;
  padding: 30px;
  margin: 0 60px;

  @media(max-width: 768px) {
    width: 100%;
  }

`


export default ViewWork